<template>
  <div class="release-box">
    <van-nav-bar title="公众号发布" />
    <div class="handle-box">
      <!-- <div class="dropdown-box">
        <van-dropdown-menu class="dropdown" active-color="#206cfe">
          <van-dropdown-item
            v-model="state"
            :options="dictionaries.publishStatus"
          />
        </van-dropdown-menu>
      </div>-->
      <ul class="tool-box">
        <li
          v-for="(item, i) in publishStatus"
          :class="{ active: i === idx }"
          @click="goSearch(item, i)"
          :key="i"
        >
          <span>{{ item.text }}</span>
          <i></i>
        </li>
      </ul>
    </div>
    <div class="list-box">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <van-cell v-for="(item, i) in list" :key="i">
          <div class="list" @click="goReleaseEdit(item)">
            <div class="list-base">{{ item.title }}</div>
            <div class="list-view">
              <div v-for="(el, j) in item.publishPosition" :key="j">
                <span>{{ el }}</span>
                <em v-if="j < item.publishPosition.length - 1"></em>
              </div>
            </div>
            <div class="list-status">
              <span>创建于{{ item.createTime | timeFilter }}</span>
              <span>
                <img :src="require(`../../assets/release/${item.state}.png`)" alt />
                <i
                  :class="{ waiting: item.state == 3, error: item.state == 4 }"
                >{{ item.stateName }}</i>
              </span>
            </div>
            <div class="release-time" v-if="item.publishDate">实际发布于{{ item.publishDate }}</div>
            <div class="reason" v-if="item.state == 4">未通过原因：{{ item.approveReason || '-' }}</div>
          </div>
        </van-cell>
      </van-list>
    </div>
    <div class="btn-box">
      <van-button round block type="info" @click="addRelease">申请发布</van-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: '',
  data() {
    return {
      idx: 0, //选中状态索引
      state: '', // 231
      needDictionaries: [231],
      publishStatus: [
        {
          text: '全部',
          value: ''
        },
        {
          text: '待审核',
          value: 1
        },
        {
          text: '待发布',
          value: 2
        },
        {
          text: '已发布',
          value: 3
        },
        {
          text: '拒绝发布',
          value: 4
        }
      ],
      dictionaries: { publishStatus: [] },
      option: [],
      list: [],
      loading: false,
      finished: false,
      loaded: false,
      query: {
        pageIndex: 1,
        pageSize: 10,
      },
    };
  },
  watch: {
    state: {
      handler: function (N, O) {
        if (N !== O) {
          this.getReleaseList();
        }
      },
    },
  },
  filters: {
    timeFilter(data) {
      return moment(new Date(data)).format('YYYY.MM.DD h:mm');
    },
  },
  mounted() {
    if (localStorage.getItem('userInfo')) {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    }
    let dictionaries = localStorage.getItem('dictionaries');
    if (dictionaries) {
      dictionaries = JSON.parse(dictionaries);
      dictionaries.forEach((el) => {
        if (this.needDictionaries.includes(el.code)) {
          this.dictionaries[el.type] = [{ text: '全部', value: '' }].concat(
            el.children.map((el) => {
              el.text = el.name;
              el.value = el.code;
              return el;
            })
          );
        }
      });
    }
  },
  methods: {
    goSearch(item, i) {
      this.idx = i;
      this.state = item.value
    },
    addRelease() {
      this.$router.push('/release/releaseAddOrEdit');
    },
    onLoad() {
      this.getReleaseList(false);
    },
    getReleaseList(isFirst = true) {
      if (isFirst) {
        this.list = [];
        this.loading = false;
        this.finished = false;
        this.loaded = false;
        this.query = {
          pageIndex: 1,
          pageSize: 10,
        };
      }
      this.loading = true;
      this.$http
        .get(
          '/selfhelpOfficialAccounts/publish/recordList/' +
          this.userInfo.companyVO.id,
          {
            ...this.query,
            state: this.state,
          }
        )
        .then((res) => {
          if (res.code === 200) {
            this.loading = false;
            this.loaded = true;
            this.list = this.list.concat(res.data);
            if (this.list.length >= res.pagination.totalRow) {
              this.finished = true;
            } else {
              this.query.pageIndex++;
            }
          }
        });
    },
    goReleaseEdit(item) {
      if (item.state === 2 || item.state === 3){
        this.$toast('审核通过.待发布、审核已通过.已发布状态不能编辑！')
        return
      }
      this.$router.push({
        path: '/release/releaseAddOrEdit',
        query: { id: item.id },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.release-box {
  height: 100%;
  width: 100%;
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
  position: relative;
  .handle-box {
    height: 55px;
    line-height: 55px;
    background: #fff;
    display: flex;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    padding: 0px 16px;
    .dropdown-box {
      flex: 1;
      .dropdown {
        width: 140px;
        height: 55px;
        line-height: 55px;
      }
    }
    .tool-box {
      display: flex;
      li {
        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          margin-right: 20px;
        }
      }
      .active {
        position: relative;
        span {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: initial;
        }
        i {
          position: absolute;
          left: 0;
          right: 20px;
          margin: 0 auto;
          width: 60%;
          height: 3px;
          background: #206cfe;
          bottom: 5px;
        }
      }
    }
    .add {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
  }
  .list-box {
    i {
      font-style: normal;
    }
    flex: 1;
    overflow-y: auto;
    padding: 15px;
    .van-cell {
      padding: 0;
      margin-bottom: 10px;
      border-radius: 4px;
    }
    .list {
      padding: 15px;
      background: #ffffff;
      border-radius: 4px;
      .list-base {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .list-view {
        padding: 5px 0 10px 0;
        border-bottom: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        > div {
          display: flex;
          align-items: center;
        }
        span {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
        em {
          margin: 0 10px;
          height: 6px;
          width: 1px;
          display: inline-block;
          background: #cccccc;
        }
      }
      .list-status {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        span {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          display: flex;
          align-items: center;
          img {
            width: 12px;
            height: 12px;
            margin-right: 5px;
          }
        }
        .waiting {
          color: #206cfe;
        }
        .error {
          color: #f44336;
        }
      }
      .release-time {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
      .reason {
        margin-top: 10px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #f44336;
      }
    }
  }
  .btn-box {
    position: absolute;
    bottom: 30px;
    width: 135px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
::v-deep .van-dropdown-menu__bar {
  height: 55px;
  box-shadow: none;
}
</style>
